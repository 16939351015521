import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Client from "../images/client.png"; // Replace with actual image path

function Company() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState({});

  useEffect(() => {
    CompanyList();
  }, []);

  // Fetching company list from API
  const CompanyList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-company",
        {}
      );
      setList(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleAddCompany = () => {
    navigate("/addcompany");
  };

  const handleEditCompany = (client) => {
    navigate("/editcompany", { state: { company: client } });
  };

  const handleDeleteCompany = (id) => {
    console.log(`Delete company with id: ${id}`);
    // Implement the delete functionality here
  };

  // Handle showing bank details in a modal
  const handleShowBankDetails = (bankDetails) => {
    setSelectedBankDetails(bankDetails);
    setShowModal(true);
  };

  const DeleteApi = async (salesId) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/delete-company",
        { c_id: salesId } // Pass sales_id in the request body
      );
      console.log(response, "response");

      if (response.status === 200) {
        alert(response.data.message);
        CompanyList(); // Refresh the sales list after deletion
      } else {
        alert("Failed to delete the sales record. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <i className="fas fa-upload"> Export</i>
          <i className="fas fa-download"> Import</i>
        </div>
        {/* <button onClick={handleAddCompany}>
          ADD COMPANY
          <i className="fas fa-plus"></i>
        </button> */}
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>COMPANY LOGO</th>
            <th>COMPANY ID</th>
            <th>COMPANY NAME</th>
            <th>REGISTRATION NO</th>
            <th>COMPANY ADDRESS</th>
            <th>COMPANY EMAIL</th>
            <th>PHONE NUMBER</th>
            <th>TYPE OF COMPANY</th>
            <th>RESPONSIBLE PERSON</th>
            <th>BANK DETAILS</th>
            {/* <th>ACTIONS</th> */}
          </tr>
        </thead>
        <tbody>
          {list?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <img
                  src={
                    `https://lunarsenterprises.com:4000/${client.c_logo}` ||
                    Client
                  }
                  alt=""
                  className="company-logo"
                />
              </td>
              <td>{client.c_id}</td>
              <td>{client.c_name}</td>
              <td>{client.c_registration}</td>
              <td>{client.c_address}</td>
              <td>{client.c_mail}</td>
              <td>{client.c_mobile}</td>
              <td>{client.c_category}</td>
              <td>{client.c_person}</td>
              <td>
                {client.bank.length > 0 ? (
                  <i
                    className="fas fa-eye"
                    onClick={() =>
                      handleShowBankDetails({
                        account_no: client.bank[0].bank_account_no,
                        ifsc_code: client.bank[0].bank_ifsc_no,
                        bank_name: client.bank[0].bank_name,
                        branch: client.bank[0].bank_branch_name,
                      })
                    }
                    style={{ cursor: "pointer", color: "#000" }}
                  ></i>
                ) : (
                  "No Bank Details"
                )}
              </td>
              {/* <td>
                <div className="action-icons">
                  <div onClick={() => handleEditCompany(client)}>
                    <i className="fas fa-edit"></i>
                  </div>
                  <div onClick={() => DeleteApi(client.c_id)}>
                    <i className="fas fa-trash"></i>
                  </div>
                </div>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for showing bank details */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Account Number:</strong> {selectedBankDetails.account_no}
          </p>
          <p>
            <strong>IFSC Code:</strong> {selectedBankDetails.ifsc_code}
          </p>
          <p>
            <strong>Bank Name:</strong> {selectedBankDetails.bank_name}
          </p>
          <p>
            <strong>Branch:</strong> {selectedBankDetails.branch}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Company;
