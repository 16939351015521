import React from "react";
import "./dashboard.scss";
import Table from "react-bootstrap/Table";
import Piechart from "../Projectpie/piechart";
import Productpie from "../Productpie/productpie";

const Dashboard = () => {
  return (
    <>
      <div className="table-container">
        <Table striped bordered hover variant="light">
          <thead>
            <tr className="double-height">
              <th>Total number of Projects</th>
              <th>Total due amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>50</td>
              <td>100000</td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th colSpan={3}>Next due</th>
            </tr>
            <tr>
              <th>No of clients</th>
              <th>Due amount</th>
              <th>Date of due amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>10</td>
              <td>5000</td>
              <td>05/05/2024</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="pie-container">
        <div className="sub-pie" style={{ borderRight: "1px solid" }}>
          <div className="text-center">Project</div>
          <Productpie />
        </div>
        <div className="sub-pie">
          <div className="text-center">Product</div>
          <Piechart />
        </div>
        {/* <div className='export-button'>
          <h4>Export</h4>
          <i className="fas fa-upload"></i>
        </div> */}
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Project ID</th>
            <th>Project Name</th>
            <th>Company</th>
            <th>Closing Date</th>
            <th>Responsible Person</th>

            <th>Mail ID</th>
            <th>Time Period</th>
            <th>Payment Amount</th>
            <th>Amount Paid</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Aims</td>
            <td>Lunar</td>
            <td>05/05/2024</td>
            <td>Manager</td>

            <td>umeshlunar@gmail.com</td>
            <td>3 weeks</td>
            <td>50000</td>
            <td>5000</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default Dashboard;
