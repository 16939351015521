import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./AddUser.scss";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [projectName, setProjectName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [closingDate, setClosingDate] = useState("");
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const [emailId, setEmailId] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const navigate = useNavigate();

  const Add_Project = async (event) => {
    event.preventDefault();
  
    try {
      const companyId = localStorage.getItem("company_id"); // Get the company_id from localStorage
  
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/add-project",
        {
          project_name: projectName,
          company_id: companyId, // This can be dynamic if needed
          closing_date: closingDate,
          responsible_person: responsiblePerson,
          email_id: emailId,
          time_period: timePeriod,
          payment_amount: paymentAmount,
          amount_paid: amountPaid,
        },
        {
          headers: {
            company_id: companyId, // Add the company_id header
          },
        }
      );
  
      console.log(response.data.message, "Message");
      alert(response.data.message);
      navigate("/clients");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };
  

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Add Project</h2>
      <Form onSubmit={Add_Project}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formProjectName">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                name="projectName"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                required
              />
            </Form.Group>

            {/* <Form.Group controlId="formCompanyId">
              <Form.Label>Company ID</Form.Label>
              <Form.Control
                type="text"
                name="companyId"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
                required
              />
            </Form.Group> */}

            <Form.Group controlId="formClosingDate">
              <Form.Label>Closing Date</Form.Label>
              <Form.Control
                type="date"
                name="closingDate"
                value={closingDate}
                onChange={(e) => setClosingDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formResponsiblePerson">
              <Form.Label>Responsible Person</Form.Label>
              <Form.Control
                type="text"
                name="responsiblePerson"
                value={responsiblePerson}
                onChange={(e) => setResponsiblePerson(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmailId">
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                type="email"
                name="emailId"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formTimePeriod">
              <Form.Label>Time Period</Form.Label>
              <Form.Control
                type="text"
                name="timePeriod"
                value={timePeriod}
                onChange={(e) => setTimePeriod(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPaymentAmount">
              <Form.Label>Payment Amount</Form.Label>
              <Form.Control
                type="number"
                name="paymentAmount"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAmountPaid">
              <Form.Label>Amount Paid</Form.Label>
              <Form.Control
                type="number"
                name="amountPaid"
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="submit-container">
          <Button variant="primary" type="submit" className="submit-button">
            Submit
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default AddUser;
