import React, { useState, useEffect } from "react";
import "./Activity.scss";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";

const Activity = () => {
  const [status, setStatus] = useState("project"); // Default status
  const [activityData, setActivityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(""); // Start date filter
  const [toDate, setToDate] = useState(""); // End date filter

  // Fetch activity logs from the API based on status and date range
  const fetchActivityLogs = async (selectedStatus, from, to) => {
    setIsLoading(true);
    try {
      const companyId = localStorage.getItem("company_id");
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-activity",
        {
          status: selectedStatus,
          fromDate: from, // Send start date
          toDate: to, // Send end date
        },
        {
          headers: {
            company_id: companyId, // Include companyId in the headers
          },
        }
      );

      if (response.data.result) {
        setActivityData(response.data.data); // Set the fetched data
      } else {
        setActivityData([]);
      }
    } catch (error) {
      console.error("Error fetching activity logs:", error);
      setActivityData([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle dropdown change and fetch new data
  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    fetchActivityLogs(newStatus, fromDate, toDate); // Fetch data when status changes
  };

  // Handle date change and fetch new data
  const handleDateChange = () => {
    fetchActivityLogs(status, fromDate, toDate); // Fetch data when date range changes
  };

  useEffect(() => {
    // Fetch initial data for the default status and default date range
    fetchActivityLogs(status, fromDate, toDate);
  }, []);

  // Render table headers based on the status
  const renderTableHeaders = () => {
    if (status === "project") {
      return (
        <tr>
          <th>S.NO</th>
          <th>PROJECT NAME</th>
          <th>CLOSING DATE</th>
          <th>MAIL ID</th>
          <th>RESPONSIBLE PERSON</th>
          <th>TIME PERIOD</th>
          <th>PAYMENT AMOUNT</th>
          <th>AMOUNT PAID</th>
          <th>LAST UPDATED</th>
        </tr>
      );
    } else if (status === "sales") {
      return (
        <tr>
          <th>S.NO</th>
          <th>SALES ID</th>
          <th>SALES UNIT</th>
          <th>SALES PRODUCT</th>
          <th>TOTAL AMOUNT</th>
          <th>SALES DISCOUNT</th>
          <th>SALES DATE</th>
          <th>SALES INVESTMENT</th>
          <th>SALES MONTHLY PAYMENT</th>
          <th>LAST EDITS</th>
        </tr>
      );
    }
  };

  // Render table rows based on the status
  const renderTableRows = () => {
    if (status === "project") {
      return activityData?.map((activity, index) => (
        <tr key={activity.p_id}>
          <td>{index + 1}</td>
          <td>{activity.p_name}</td>
          <td>{moment(activity.p_closing_date).format("YYYY-MM-DD")}</td>
          <td>{activity.p_mail_id}</td>
          <td>{activity.p_responsible_person}</td>
          <td>{activity.p_time_period}</td>
          <td>{activity.p_payment_amount}</td>
          <td>{activity.p_amount_paid}</td>
          <td>{moment(activity.p_last_update).format("YYYY-MM-DD")}</td>
        </tr>
      ));
    } else if (status === "sales") {
      return activityData?.map((activity, index) => (
        <tr key={activity.sales_id}>
          <td>{index + 1}</td>
          <td>{activity.sales_id}</td>
          <td>{activity.sales_unit}</td>
          <td>{activity.sales_product}</td>
          <td>{activity.sales_total_amount}</td>
          <td>{activity.sales_discount}</td>
          <td>{moment(activity.sales_date).format("DD/MM/YYYY")}</td>
          <td>{activity.sales_investment}</td>
          <td>{activity.sales_monthly_payment_amount}</td>
          <td>{moment(activity.sales_last_edit).format("DD/MM/YYYY")}</td>
        </tr>
      ));
    }
  };

  return (
    <>
      <div className="activity-head">
        <h3>Activity Logs</h3>
      </div>

      <div className="date-search">
        <div className="dates">
          <div className="start-date">
            <h5>From</h5>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              onBlur={handleDateChange} // Trigger data fetch when date is selected
            />
          </div>
          <div className="start-date">
            <h5>To</h5>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              onBlur={handleDateChange} // Trigger data fetch when date is selected
            />
          </div>
        </div>

        <div className="status-dropdown">
          <h5>Status</h5>
          <select value={status} onChange={handleStatusChange}>
            <option value="project">Project</option>
            <option value="sales">Sales</option>
          </select>
        </div>
      </div>

      <div className="sub-head">
        <div className="head-icons">
          <i className="fas fa-upload"> export</i>
          <i className="fas fa-download"> import</i>
        </div>
      </div>

      <Table striped bordered hover variant="light">
        <thead>{renderTableHeaders()}</thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : activityData?.length > 0 ? (
            renderTableRows()
          ) : (
            <tr>
              <td colSpan="7">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Activity;
