import React, { useState, useEffect } from "react";
import "./Email.scss";
import Modal from "./Modal"; // Import the modal component

const Paragraph = ({ content, title, onSelect, isSelected, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onEdit(editedContent); // Pass the edited content to the parent
  };

  const handleChange = (e) => {
    setEditedContent(e.target.value);
  };

  return (
    <div className="formates">
      <div className="formate-heading">
        <h6>{title}</h6> {/* Display the title passed as a prop */}
        <input type="checkbox" onChange={onSelect} checked={isSelected} />
      </div>

      <div className="para-edit">
        {isEditing ? (
          <textarea value={editedContent} onChange={handleChange} />
        ) : (
          <p>{editedContent}</p>
        )}
        <i className="fas fa-edit" onClick={handleEditClick}></i>
        {isEditing && <button onClick={handleSaveClick}>Save</button>}
      </div>
    </div>
  );
};

const Email = () => {
  const [formats] = useState([
    `Dear Sir/Ma'am,\n\nWelcome to our service! We are excited to have you on board.\n\nBest regards,\n[Your Company Name]`,
    `Dear Sir/Ma'am,\n\nWe are reaching out to request further information regarding your recent inquiry.\n\nThank you!\n[Your Company Name]`,
    `Dear Sir/Ma'am,\n\nThank you for your support and for choosing our service. We appreciate your business!\n\nBest wishes,\n[Your Company Name]`,
  ]);

  // Titles corresponding to each format
  const titles = [
    "Welcome Email",
    "Request Email",
    "Thank You Email",
  ];

  const [selectedFormats, setSelectedFormats] = useState([]);
  const [editedContents, setEditedContents] = useState(formats); // New state to keep track of edited contents
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailList, setEmailList] = useState([]); // State to store email IDs

  // Fetch client emails from API
  useEffect(() => {
    const fetchClientEmails = async () => {
      const CompanyId = localStorage.getItem("company_id"); // Retrieve the CompanyId from local storage
      try {
        const response = await fetch(
          "https://lunarsenterprises.com:4000/aims/clients",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              company_id: CompanyId, // Add CompanyId to headers
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch client emails");
        }

        const clients = await response.json();
        const emails = clients.map((client) => client.client_email_id);
        setEmailList(emails); // Store fetched emails in state
      } catch (error) {
        console.error("Error fetching client emails:", error);
      }
    };

    fetchClientEmails();
  }, []); // Empty dependency array means this runs once on mount

  const handleSelectFormat = (format) => {
    setSelectedFormats((prev) => {
      if (prev.includes(format)) {
        return prev.filter((f) => f !== format);
      }
      return [...prev, format];
    });
  };

  const handleShare = () => {
    const shareContent = selectedFormats.join("\n\n");
    const emailSubject = "Shared Email Formats";
    const emailBody = encodeURIComponent(shareContent);
    const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;

    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareContent
    )}`;

    window.open(emailLink, "_blank");
    window.open(whatsappLink, "_blank");
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditContent = (index, newContent) => {
    const updatedContents = [...editedContents];
    updatedContents[index] = newContent; // Update the edited content at the specific index
    setEditedContents(updatedContents);
  };

  const handleConfirmSendEmails = async () => {
    const emailSubject = "Welcome Mail";
    const emailContent = selectedFormats.map((format) => {
      const index = formats.indexOf(format);
      return editedContents[index]; // Get the edited content for the selected format
    }).join("\n\n"); // Combine all selected formats as email body

    const payload = {
      email: selectedEmails,
      subject: emailSubject,
      content: emailContent,
    };

    // Send emails logic
    try {
      const response = await fetch(
        "https://lunarsenterprises.com:4000/aims/bulk/email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Emails sent successfully:", result);
        alert("Emails sent successfully!");
      } else {
        console.error("Failed to send emails");
        alert("Failed to send emails");
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      alert("Error sending emails");
    } finally {
      setIsModalOpen(false);
      setSelectedEmails([]); // Reset selected emails
    }
  };

  return (
    <>
      <div className="activity-head">
        <h3>Emails/Updates</h3>
      </div>
      <div className="mail-box">
        <h5>Email Format</h5>
        <div className="mail-formates">
          {formats.map((content, index) => (
            <Paragraph
              key={index}
              content={content}
              title={titles[index]} // Pass the corresponding title
              onSelect={() => handleSelectFormat(content)}
              isSelected={selectedFormats.includes(content)}
              onEdit={(newContent) => handleEditContent(index, newContent)} // Pass the handler to update content
            />
          ))}
        </div>
        <div>
          <button onClick={handleShare} className="share-button">
            Share Selected Formats
          </button>
          <button onClick={handleOpenModal} className="share-button">
            Send Bulk Email
          </button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmSendEmails}
        emailList={emailList} // Pass the fetched email list to the modal
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
      />
    </>
  );
};

export default Email;
