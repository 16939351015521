import React, { useEffect, useState } from "react";
import "./Sales.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Sales = () => {
  const navigate = useNavigate();

  useEffect(() => {
    SalesList();
  }, []);

  const [list, setList] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [show, setShow] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [lastEditDetails, setLastEditDetails] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [filterAmount, setFilterAmount] = useState("");
  const [filterDate, setFilterDate] = useState("");

  const SalesList = async () => {
    try {
      const companyId = localStorage.getItem("company_id"); // Get companyId from localStorage
  
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-sales",
        {}, // Keep the request body empty if not needed
        {
          headers: {
            company_id: companyId, // Pass companyId in headers
          },
        }
      );
      
      setList(response.data.list);
      setFilteredClients(response.data.list); // Initialize filteredClients
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };
  

  const handleAddUser = () => {
    navigate("/addsales");
  };

  const handleEditUser = (salesId) => {
    navigate(`/editsales/${salesId}`);
  };

  const DeleteApi = async (salesId) => {
    try {
        const response = await axios.post(
            "https://lunarsenterprises.com:4000/aims/delete-sales",
            { sales_id: salesId } // Pass sales_id in the request body
        );
        console.log(response, "response");

        if (response.status === 200) {
            alert(response.data.message);
            SalesList(); // Refresh the sales list after deletion
        } else {
            alert("Failed to delete the sales record. Please try again.");
        }
    } catch (error) {
        console.error("Error:", error);
        alert("An error occurred. Please try again.");
    }
};


  const handleLastEditClick = (details) => {
    setLastEditDetails(details);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleFilterClose = () => setFilterShow(false);
  const handleFilterShow = () => setFilterShow(true);

  const handleFilter = () => {
    let filtered = list;
    if (filterName) {
      filtered = filtered.filter((client) =>
        client.client_name.toLowerCase().includes(filterName.toLowerCase())
      );
    }
    if (filterAmount) {
      filtered = filtered.filter((client) =>
        client.sales_total_amount.toString().includes(filterAmount)
      );
    }
    if (filterDate) {
      filtered = filtered.filter((client) =>
        client.sales_date.includes(filterDate)
      );
    }
    setFilteredClients(filtered);
    handleFilterClose();
  };

  const handleClearFilter = () => {
    setFilterName("");
    setFilterAmount("");
    setFilterDate("");
    setFilteredClients(list);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-CA", options);
  };

  const handleSearchChange = (e) => {
    setFilterName(e.target.value);
    const filtered = list.filter((client) =>
      client.client_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredClients(filtered);
  };

  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <button onClick={handleAddUser}>
            ADD SALES
            <i className="fas fa-plus"></i>
          </button>
        </div>
        <div className="search-actions">
          <input
            type="text"
            placeholder="Search by name"
            value={filterName}
            onChange={handleSearchChange}
          />
          <button onClick={handleFilterShow}>
            Filters
            <i className="fas fa-filter"></i>
          </button>
        </div>
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>CLIENT NAME</th>
            <th>COMPANY NAME</th>
            <th>PRODUCT</th>
            <th>UNITS</th>
            <th>DISCOUNT</th>
            <th>TOTAL AMOUNT</th>
            <th>DATE OF SALES</th>
            <th>MONTHLY PAYOUT AMOUNT</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{client.client_name}</td>
              <td>{client.c_name}</td>
              <td>{client.sales_product}</td>
              <td>{client.sales_unit}</td>
              <td>{client.sales_discount}</td>
              <td>{client.sales_total_amount}</td>
              <td>
                <h6>{formatDate(client.sales_date)}</h6>
                {/* Optionally display last edit icon here */}
                {/* <i
                  className="fas fa-eye"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLastEditClick(client)}
                ></i> */}
              </td>
              <td>{client.sales_monthly_payment_amount}</td>
              <td>
                <div style={{ display: "flex", gap: "10px" }}>
                  <i
                    className="fas fa-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditUser(client.sales_id)} 
                  ></i>
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteApi(client.sales_id)}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Last Edited Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lastEditDetails && (
            <>
              <p>
                <strong>CONTRACT NO:</strong>{" "}
                {lastEditDetails.client_contract_number}
              </p>
              <p>
                <strong>NAME:</strong> {lastEditDetails.client_name}
              </p>
              <p>
                <strong>PHONE NUMBER:</strong> {lastEditDetails.client_number}
              </p>
              <p>
                <strong>EMAIL ID:</strong> {lastEditDetails.client_email_id}
              </p>
              <p>
                <strong>PASSPORT NO:</strong> {lastEditDetails.client_passport}
              </p>
              <p>
                <strong>CONTRACT START DATE:</strong>{" "}
                {formatDate(lastEditDetails.client_contract_start_date)}
              </p>
              <p>
                <strong>CONTRACT END DATE:</strong>{" "}
                {formatDate(lastEditDetails.client_contract_end_date)}
              </p>
              <p>
                <strong>INSURANCE PREMIUM:</strong>{" "}
                {lastEditDetails.client_insurance_status}
              </p>
              <p>
                <strong>CLIENT STATUS:</strong> {lastEditDetails.client_status}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={filterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apply Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Filter by name"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Filter by amount"
            value={filterAmount}
            onChange={(e) => setFilterAmount(e.target.value)}
          />
          <input
            type="date"
            placeholder="Filter by date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClearFilter}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleFilter}>
            Apply Filters
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sales;
