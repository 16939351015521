import React, { useEffect, useState } from "react";
import "./clients.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Clients = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search input

  // Fetch project list on component mount
  useEffect(() => {
    ProjectList();
  }, []);

  // Fetch project list from API
  const ProjectList = async () => {
    try {
      const companyId = localStorage.getItem("company_id"); // Retrieve company_id from localStorage
      const { data } = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-project",
        {},
        {
          headers: {
            company_id: companyId, // Add company_id as a header
          },
        }
      );

      setList(data.data);
      setFilteredClients(data.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      alert("An error occurred while fetching the project list. Please try again.");
    }
  };

  // Navigate to Add User page
  const handleAddUser = () => {
    navigate("/adduser");
  };

  // Navigate to Edit User page with client data
  const handleEditUser = (client) => {
    navigate("/edituser", { state: { client } });
  };

  // Delete a project by project ID
  const DeleteApi = async (p_id) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/delete-project",
        { p_id } // Pass project ID in the request body
      );

      if (response.status === 200) {
        alert(response.data.message);
        ProjectList(); // Refresh the project list after deletion
      } else {
        alert("Failed to delete the project. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting project:", error);
      alert("An error occurred while trying to delete the project. Please try again.");
    }
  };

  // Handle search input change
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase(); // Convert search term to lowercase
    setSearchTerm(value);

    // Filter clients based on the search term (name matching)
    const filtered = list.filter((client) =>
      client.p_name.toLowerCase().includes(value)
    );

    setFilteredClients(filtered); // Update the filtered clients list
  };

  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <i className="fas fa-upload"> Export</i>
          <i className="fas fa-download"> Import</i>
        </div>
        <div className="search-actions">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm} // Bind input value to searchTerm state
            onChange={handleSearch} // Handle search on input change
          />
          <button onClick={handleAddUser}>
            ADD PROJECT <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>PROJECT ID</th>
            <th>PROJECT NAME</th>
            <th>COMPANY</th>
            <th>CLOSING DATE</th>
            <th>RESPONSIBLE PERSON</th>
            <th>MAIL ID</th>
            <th>TIME PERIOD</th>
            <th>PAYMENT AMOUNT</th>
            <th>AMOUNT PAID</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{client.p_id}</td>
              <td>{client.p_name}</td>
              <td>{client.p_company_name}</td>
              <td>
                {client.p_closing_date
                  ? moment(client.p_closing_date).format("YYYY-MM-DD")
                  : "N/A"}
              </td>
              <td>{client.p_responsible_person}</td>
              <td>{client.p_mail_id}</td>
              <td>{client.p_time_period}</td>
              <td>{client.p_payment_amount}</td>
              <td>{client.p_amount_paid}</td>
              <td>
                <div style={{ display: "flex", gap: "10px" }}>
                  <i
                    className="fas fa-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditUser(client)}
                  ></i>
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteApi(client.p_id)}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Clients;
