// Modal.js
import React from 'react';
import './Modal.scss'; // Add your modal styles

const Modal = ({ isOpen, onClose, onConfirm, emailList, selectedEmails, setSelectedEmails }) => {
  const handleEmailChange = (email) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails(selectedEmails.filter((e) => e !== email));
    } else {
      setSelectedEmails([...selectedEmails, email]);
    }
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedEmails(emailList);
    } else {
      setSelectedEmails([]);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h4>Select Emails</h4>
        
        <div className="select-all">
          <label>
            <input
              type="checkbox"
              checked={selectedEmails.length === emailList.length && emailList.length > 0}
              onChange={handleSelectAllChange}
            />
            Select All
          </label>
        </div>

        {emailList.map((email) => (
          <div key={email} className="email-checkbox">
            <label>
              <input
                type="checkbox"
                checked={selectedEmails.includes(email)}
                onChange={() => handleEmailChange(email)}
              />
              {email}
            </label>
          </div>
        ))}
        
        <div className="modal-buttons">
          <button className="cancel-button" onClick={onClose}>Cancel</button>
          <button className="confirm-button" onClick={onConfirm}>Send Emails</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
