import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./AddUser.scss";
import { useNavigate, useLocation } from "react-router-dom";

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { client } = location.state; // Get client data passed from Clients component

  const [projectName, setProjectName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [closingDate, setClosingDate] = useState("");
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const [emailId, setEmailId] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [amountPaid, setAmountPaid] = useState("");

  useEffect(() => {
    if (client) {
      // Set form fields with the current project details
      setProjectName(client.p_name);
      setCompanyId(client.p_c_id); // Uncomment if you need to use companyId
      setClosingDate(client.p_closing_date);
      setResponsiblePerson(client.p_responsible_person);
      setEmailId(client.p_mail_id);
      setTimePeriod(client.p_time_period);
      setPaymentAmount(client.p_payment_amount);
      setAmountPaid(client.p_amount_paid);
    }
  }, [client]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = {
      p_id: client.p_id, // Include project ID for the update
      p_name: projectName,
      p_c_id: companyId, // Uncomment if you use companyId
      p_closing_date: closingDate,
      p_responsible_person: responsiblePerson,
      p_mail_id: emailId,
      p_time_period: timePeriod,
      p_payment_amount: paymentAmount,
      p_amount_paid: amountPaid,
    };

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/edit-project",
        updatedData
      );
      alert("Project updated successfully!");
      navigate("/clients"); // Navigate back to the clients page or wherever appropriate
    } catch (error) {
      console.error("Error updating project:", error);
      alert("An error occurred while updating the project. Please try again.");
    }
  };

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Edit Project</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formProjectName">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                name="projectName"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                required
              />
            </Form.Group>

            {/* Uncomment if you want to include Company ID */}
            {/* <Form.Group controlId="formCompanyId">
              <Form.Label>Company ID</Form.Label>
              <Form.Control
                type="text"
                name="companyId"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
                required
              />
            </Form.Group> */}

            <Form.Group controlId="formClosingDate">
              <Form.Label>Closing Date</Form.Label>
              <Form.Control
                type="date"
                name="closingDate"
                value={closingDate}
                onChange={(e) => setClosingDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formResponsiblePerson">
              <Form.Label>Responsible Person</Form.Label>
              <Form.Control
                type="text"
                name="responsiblePerson"
                value={responsiblePerson}
                onChange={(e) => setResponsiblePerson(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmailId">
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                type="email"
                name="emailId"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formTimePeriod">
              <Form.Label>Time Period</Form.Label>
              <Form.Control
                type="text"
                name="timePeriod"
                value={timePeriod}
                onChange={(e) => setTimePeriod(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPaymentAmount">
              <Form.Label>Payment Amount</Form.Label>
              <Form.Control
                type="number"
                name="paymentAmount"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAmountPaid">
              <Form.Label>Amount Paid</Form.Label>
              <Form.Control
                type="number"
                name="amountPaid"
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="submit-container">
          <Button variant="primary" type="submit" className="submit-button">
            Update
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EditUser;
