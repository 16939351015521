import { useState } from "react";
import "./App.scss";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./Components/navbar/Navbar";
import Hero from "./Components/hero/Hero";
import Dashboard from "./Components/dashboard/Dashboard";
import Clients from "./Components/clients/Clients";
import Bank from "./Components/Bank/Bank";
import Sales from "./Components/sales/Sales";
import Cheques from "./Components/cheques/Cheques";
import Email from "./Components/email/Email";
import Internal from "./Components/internal/Internal";
import Activity from "./Components/activity/Activity";
import Login from "./Components/login/Login";
import Signup from "./Components/Sign-in/Sign-up";
import Company from "./Components/Company/Company";
import Adduser from "./Components/clients/Adduser";
import EditUser from "./Components/clients/Editclient";
import AddCompany from "./Components/Company/Addcompany";
import Editcompany from "./Components/Company/Editcompany";
import AddSales from "./Components/sales/AddSales";
import EditSales from "./Components/sales/EditSales";
import AddBank from "./Components/Bank/AddBank";
import EditBank from "./Components/Bank/EditBank";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
    <Navigate to="/dashboard" />;
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    <Navigate to="/" />;
  };

  return (
    <div className="App">
      <Router>
        {isLoggedIn ? (
          <>
            <Navbar handleLogout={handleLogout} />
            <div className="main-container">
              <Hero />
              <Routes>
                {/* <Route path="/company" element={<Company />} /> */}
                <Route path="/clients" element={<Clients />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/adduser" element={<Adduser />} />
                <Route path="/edituser" element={<EditUser />} />
                <Route path="/addcompany" element={<AddCompany />} />
                <Route path="/editcompany" element={<Editcompany />} />
                <Route path="/bank" element={<Bank />} />
                <Route path="/addbank" element={<AddBank />} />
                <Route path="/editbank" element={<EditBank />} />


                <Route path="/sales" element={<Sales />} />
                <Route path="/addsales" element={<AddSales />} />
                <Route path="/editsales/:salesId" element={<EditSales />} />

                <Route path="/cheques" element={<Cheques />} />
                <Route path="/email" element={<Email />} />
                <Route path="/internal" element={<Internal />} />
                <Route path="/activity" element={<Activity />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/" element={<Login handleLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;

