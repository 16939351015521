import React, { useEffect, useState } from "react";
import "./Bank.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Bank = () => {
  const navigate = useNavigate();

  useEffect(() => {
    BankList();
  }, []);

  const [list, setList] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search input

  const BankList = async () => {
    try {
      // Retrieve company_id from localStorage
      const companyId = localStorage.getItem("company_id");
  
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-bank",
        {},
        {
          headers: {
            company_id: companyId,  // Add company_id in the headers
          },
        }
      );
  
      setList(response.data.data);
      setFilteredClients(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };
  

  const handleAddUser = () => {
    navigate("/addbank");
  };

  const handleEditUser = (client) => {
    navigate("/editbank", { state: { client } });
  };

  const DeleteApi = async (bankId) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/delete-bank",
        { bank_id: bankId } // Pass sales_id in the request body
      );
      console.log(response, "response");

      if (response.status === 200) {
        alert(response.data.message);
        BankList(); // Refresh the sales list after deletion
      } else {
        alert("Failed to delete the sales record. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  // Handle search
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase(); // Get the search term in lowercase
    setSearchTerm(value);

    // Filter clients based on the search term (name matching)
    const filtered = list.filter((client) =>
      client.bank_as_per_account_name.toLowerCase().includes(value)
    );

    setFilteredClients(filtered); // Update the filtered clients list
  };

  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <i className="fas fa-upload"> export</i>
          <i className="fas fa-download">import</i>
        </div>
        <div className="search-actions">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm} // Bind input value to searchTerm state
            onChange={handleSearch} // Handle search on input change
          />
          <button onClick={handleAddUser}>
            ADD BANK
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>NAME AS PER BANK</th>
            <th>ACCOUNT NUMBER</th>
            <th>IBAN/IFSCE/ROUTING NO</th>
            <th>NAME OF BANK</th>
            <th>BRANCH</th>
            {/* <th>WIRE NUMBER</th> */}
            {/* <th>INSURANCE AMOUNT</th> */}
            <th>FIRST PAYOUT DATE</th>
            <th>PAYMENT DATE</th>

            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{client.bank_as_per_account_name}</td>
              <td>{client.bank_account_no}</td>
              <td>{client.bank_ifsc_no}</td>
              <td>{client.bank_name}</td>
              <td>{client.bank_branch_name}</td>
              {/* <td>{client.wire_numbers}</td> */}
              {/* <td>{client.insurance_amount}</td> */}
              <td>
                {client.first_payout_date
                  ? moment(client.first_payout_date).format("YYYY-MM-DD")
                  : "N/A"}
              </td>
              <td>
                {client.payment_due
                  ? moment(client.payment_due).format("YYYY-MM-DD")
                  : "N/A"}
              </td>

              <td>
                <div style={{ display: "flex",justifyContent:'center' }}>
                  {/* <i
                    className="fas fa-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditUser(client)}
                  ></i> */}
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteApi(client.bank_id)}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Bank;
