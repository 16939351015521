import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom"; // useLocation to access passed data

const EditBank = () => {
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [wireNo, setWireNo] = useState("");
  const [insuranceAmount, setInsuranceAmount] = useState("");
  const [firstPayout, setFirstPayout] = useState("");
  const [due, setDue] = useState("");
  
  const navigate = useNavigate();
  const location = useLocation(); // Get data passed from the previous page
  const bankData = location.state?.client; // Access passed bank data

  // Populate the form with existing bank data when the component loads
  useEffect(() => {
    if (bankData) {
      setBankName(bankData.bank_name || "");
      setBranchName(bankData.bank_branch_name || "");
      setAccountNo(bankData.bank_account_no || "");
      setIfscCode(bankData.bank_ifsc_no || "");
      setAccountHolderName(bankData.bank_as_per_account_name || "");
      setWireNo(bankData.wire_numbers || "");
      setInsuranceAmount(bankData.insurance_amount || "");
      setFirstPayout(bankData.first_payout_date || "");
      setDue(bankData.payment_due || "");
    }
  }, [bankData]);

  // Handle form submission for editing bank details
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const requestBody = {
      bank_id: bankData?.bank_id,
      bank_user_id: null, // You can set the actual user ID here
      bank_name: bankName,
      bank_branch_name: branchName,
      bank_account_no: accountNo,
      bank_ifsc_no: ifscCode,
      bank_as_per_account_name: accountHolderName,
      wire_numbers: wireNo,
      insurance_amount: insuranceAmount,
      first_payout_date: firstPayout,
      payment_due: due
    };

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/edit-bank",
        requestBody
      );
      
      if (response.status === 200) {
        alert(response.data.message);
        navigate("/bank"); // Navigate back to the list page after successful edit
      } else {
        alert("Failed to update bank details. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Container className="add-user-container">
      <h2 className="adduser"> Edit Bank</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formBankName">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                name="bankName"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBranchName">
              <Form.Label>Branch Name</Form.Label>
              <Form.Control
                type="text"
                name="branchName"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAccountNo">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                name="accountNo"
                value={accountNo}
                onChange={(e) => setAccountNo(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formIfscCode">
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control
                type="text"
                name="ifscCode"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formAccountHolderName">
              <Form.Label>Account Holder Name</Form.Label>
              <Form.Control
                type="text"
                name="accountHolderName"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formWireNo">
              <Form.Label>Wire Number</Form.Label>
              <Form.Control
                type="text"
                name="wireNo"
                value={wireNo}
                onChange={(e) => setWireNo(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formInsuranceAmount">
              <Form.Label>Insurance Amount</Form.Label>
              <Form.Control
                type="text"
                name="insuranceAmount"
                value={insuranceAmount}
                onChange={(e) => setInsuranceAmount(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formFirstPayout">
              <Form.Label>First Payout Date</Form.Label>
              <Form.Control
                type="date"
                name="firstPayout"
                value={firstPayout}
                onChange={(e) => setFirstPayout(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formDue">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                name="due"
                value={due}
                onChange={(e) => setDue(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="submit-container">
          <Button variant="primary" type="submit" className="submit-button">
            Update
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EditBank;
