import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { NavLink } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import Profile from "../images/profile.png";
import Facebook from "../images/fb.png";
import Google from "../images/google.png";
import Loginimg from "../images/login-img.png";

const Login = ({ handleLogin, handleLogout }) => {
  const [data, setData] = useState({
    user_email: "",
    user_password: "",
  });
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4000/aims/list-company",
          {}
        );
        if (response.data.result) {
          setCompanyList(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail && savedPassword) {
      setData({ user_email: savedEmail, user_password: savedPassword });
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignInClick = async (event) => {
    event.preventDefault();
  
    if (!selectedCompany) {
      setError("Please select a company.");
      setTimeout(() => setError(null), 3000);
      return;
    }
  
    if (data.user_email.trim() === "" || data.user_password.trim() === "") {
      setError("Email and password are required.");
      setTimeout(() => setError(null), 3000);
      return;
    }
  
    if (!validateEmail(data.user_email)) {
      setError("Please enter a valid email address.");
      setTimeout(() => setError(null), 3000);
      return;
    }
  
    try {
      const response = await fetch(
        "https://lunarsenterprises.com:4000/aims/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_email: data.user_email,
            user_password: data.user_password,
            company_id: selectedCompany,  // Include company_id in the request body
          }),
        }
      );
  
      const resData = await response.json();
  
      if (response.ok && resData.result) {
        // Save email, password, token, and company_id to localStorage
        localStorage.setItem("email", data.user_email);
        localStorage.setItem("password", data.user_password);
        localStorage.setItem("token", resData.token);
        localStorage.setItem("company_id", selectedCompany);  // Save company_id
  
        setShowOtp(true);
        handleLogin();
      } else {
        setError(resData.message);
        setTimeout(() => setError(null), 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };
  

  return (
    <div className="login">
      <img src={Loginimg} alt="hello" />
      <div className="form-inputs">
        <img src={Profile} alt="" />
        <h2>Login</h2>

        <form onSubmit={handleSignInClick}>
          {/* Company Dropdown */}
          <div className="inputs">
            <label htmlFor="company">Select Company</label>
            <select
              id="company"
              className="form-control"
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
              required
            >
              <option value="">Select a company</option>
              {companyList.map((company) => (
                <option key={company.c_id} value={company.c_id}>
                  {company.c_name}
                </option>
              ))}
            </select>
          </div>

          {/* Email Field */}
          <div className="inputs">
            <label htmlFor="user_email">Email</label>
            <input
              type="text"
              className="form-control login-input"
              placeholder="Enter Email"
              aria-label="Enter User Name"
              value={data.user_email}
              onChange={handleChange}
              id="user_email"
            />
          </div>

          {/* Password Field */}
          <div className="inputs">
            <label htmlFor="user_password">Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control login-input"
                placeholder="Enter Password"
                aria-label="Enter Password"
                value={data.user_password}
                onChange={handleChange}
                id="user_password"
              />
              <i
                className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                onClick={togglePasswordVisibility}
              ></i>
            </div>
          </div>

          {error && (
            <p className="error" style={{ color: "red" }}>
              {error}
            </p>
          )}

          <div className="button">
            <button type="submit">Log In</button>
          </div>
          {/* <h6>
            Don't Have An Account?{" "}
            <span>
              <NavLink to="/sign-up">Sign Up</NavLink>
            </span>
          </h6> */}
          {/* <h5>or continue with</h5> */}
          {/* <div className="links">
            <img src={Google} alt="" />
            <img src={Facebook} alt="" />
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default Login;
